import React, { Component } from 'react';
import { graphql } from 'gatsby';
import { THEMES } from "../context/withApp";
import Page from "../components/Page/Page";
import SEO from "../components/SEO/SEO";
import Grid from '../components/UI/Grid/Grid';
import Image from "../components/UI/Image/Image";

import type from '../styles/typography.module.scss';

class About extends Component {
    render() {
        return (
            <Page { ...this.props.page } theme={ THEMES.light }>
                <SEO title="About" keywords={[`digital`, `design`, `front-end development`, `freelance`, `portfolio`, `southampton`, `hampshire`]} setPageTitle={true}/>

                <h1>I love what I do.</h1>
                
                <p className={type.lead}>Hello, my name is Pete Luffman and I run a mighty one-man freelance digital design and front-end development studio in Southampton, Hampshire. I consider myself to be extremely fortunate because my job simply entails doing what I love. I'm passionate about simplicity, ease of use, aesthetic beauty and delivering a first-class product.</p>

                <Grid>
                    <div>
                        <p>Since 2004 I’ve been creating high-end solutions for leading brands. I work independently for agencies, corporations and start-ups in the UK and Europe on anything digital or interactive. I excel at integrating seamlessly into my clients’ teams, becoming their go-to specialist of choice.</p>
                        <p>I’m an unusual breed of person that is equally at home in both design and code, and that allows me to understand the process from the perspective of both designer and developer. I love both disciplines and as such I strive to be the best I can be in each.</p>
                        <p>Over the years I've worked with many clients from start-up businesses to large well known brands in many different industries: travel, retail, fashion, healthcare and technology to name but a few. Gatwick Airport, B&Q, White Stuff and Crew Clothing are just a few of the brands I’ve been privileged to work with over the years.</p>
                        <p>When I’m not working, I enjoy time with my wife and kids, dabble in diy, keeping fit and spending far too much time on reddit.</p>
                    </div>
                    <div>
                        <Image fluid={this.props.data.file.childImageSharp.fluid} maxWidth={320}/>
                    </div>
                </Grid>
            </Page>
        );
    }
};

export const query = graphql`
  query HomePageQuery {
    file(relativePath: {eq: "mugshot.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 320, toFormat: JPG, quality: 90) {
                ...GatsbyImageSharpFluid
            }
        }
      }
  }
`

export default About;